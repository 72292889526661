import {combineReducers, Reducer} from 'redux';
import {routerReducer} from '@angular-redux/router';
import {ILocationsState, LocationsReducer} from './reducers/locations.reducer';
import {IBookingTemplatesState, BookingTemplatesReducer, IStateBookingTemplate} from './reducers/booking-templates.reducer';
import {IBookingsState, BookingsReducer, IStateBooking} from './reducers/bookings.reducer';
import {ILocationGroupsState, LocationGroupsReducer, IStateLocationGroups} from './reducers/location-groups.reducer';
import {IStateUser, IUsersState, UsersReducer} from './reducers/users.reducer';
import {IRolesState, IStateRole, RolesReducer} from './reducers/roles.reducer';
import {IMedia, IMediasState, MediasReducer} from './reducers/medias.reducer';
import {
  IExternalResourcesState,
  ExternalResourcesReducer,
  IStateExternalResource,
} from './reducers/external-resources.reducer';
import {BookingsProcessingReducer} from './reducers/bookings-processing.reducer';
import {IMetaState} from './reducers/abstract.reducer';
import { ISettingsState, SettingsReducer } from './reducers/settings.reducer';
import { ISettingsObject } from '../../../../common/interfaces/settings';
import { ICategoriesState, CategoriesReducer } from './reducers/categories.reducer';
import { ICategoryAttributes } from '../../../../common/interfaces/category';

export interface IAppState {
  locations: ILocationsState;
  locationGroups: ILocationGroupsState;
  bookings: IBookingsState;
  bookingsProcessing: IBookingsState;
  users: IUsersState;
  bookingTemplates: IBookingTemplatesState;
  medias: IMediasState;
  roles: IRolesState;
  router: string;
  externalResources: IExternalResourcesState;
  settings: ISettingsState;
  categories: ICategoriesState;
}

const locationsReducer = LocationsReducer.getInstance();
const locationGroupsReducer = LocationGroupsReducer.getInstance();
const bookingsReducer = BookingsReducer.getInstance();
const bookingsProcessingReducer = BookingsProcessingReducer.getInstance();
const usersReducer = UsersReducer.getInstance();
const bookingTemplatesReducer = BookingTemplatesReducer.getInstance();
const rolesReducer = RolesReducer.getInstance();
const mediasReducer = MediasReducer.getInstance();
const externalResourcesReducer = ExternalResourcesReducer.getInstance();
const settingsReducer = SettingsReducer.getInstance();
const categoriesReducer = CategoriesReducer.getInstance();

export const DEFAULT_STATE = {
  locations: locationsReducer.DEFAULT_STATE,
  locationGroups: locationGroupsReducer.DEFAULT_STATE,
  bookings: bookingsReducer.DEFAULT_STATE,
  bookingsProcessing: bookingsProcessingReducer.DEFAULT_STATE,
  users: usersReducer.DEFAULT_STATE,
  bookingTemplates: bookingTemplatesReducer.DEFAULT_STATE,
  medias: mediasReducer.DEFAULT_STATE,
  roles: rolesReducer.DEFAULT_STATE,
  externalResources: externalResourcesReducer.DEFAULT_STATE,
  settings: settingsReducer.DEFAULT_STATE,
  categories: categoriesReducer.DEFAULT_STATE,
  router: '/',
};

export const rootReducer: Reducer<IAppState> = combineReducers({
  locations: (state: IMetaState<ILocationsState>, action) => locationsReducer.reduce(state, action),
  locationGroups: (state: IMetaState<IStateLocationGroups>, action) => locationGroupsReducer.reduce(state, action),
  bookings: (state: IMetaState<IStateBooking>, action) => bookingsReducer.reduce(state, action),
  bookingsProcessing: (state: IMetaState<IStateBooking>, action) => bookingsProcessingReducer.reduce(state, action),
  users:  (state: IMetaState<IStateUser>, action) => usersReducer.reduce(state, action),
  bookingTemplates: (state: IMetaState<IStateBookingTemplate>, action) => bookingTemplatesReducer.reduce(state, action),
  medias: (state: IMetaState<IMedia>, action) => mediasReducer.reduce(state, action),
  roles: (state: IMetaState<IStateRole>, action) => rolesReducer.reduce(state, action),
  externalResources: (state: IMetaState<IStateExternalResource>, action) => externalResourcesReducer.reduce(state, action),
  settings: (state: IMetaState<ISettingsObject>, action) => settingsReducer.reduce(state, action),
  categories: (state: IMetaState<ICategoryAttributes>, action) => categoriesReducer.reduce(state, action),
  router: routerReducer,
});
