import {Component, Injectable, Input} from '@angular/core';
import {LocationsController} from '../../../redux/actions/locations/locations.controller';
import {LocationsSelector} from '../../../redux/reducers/locations.reducer';
import {LocationsActionsCreator} from '../../../redux/actions/locations/locations.action';
import {Resolve} from '@angular/router';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../../../redux/store';
import {LOCATIONS} from '../../../../../../common/constants';
import {LocationRuntimeModalBoxComponent} from '../../ui-elements/modal-box/location-runtime-modal-box/location-runtime-modal-box.component';
import * as _ from 'lodash';


@Component({
  selector: 'app-locations',
  template: `
    <app-table-page
      [name]="name"
      [title]="'Standorte anlegen und verwalten.'"
      [useToggle]="true"
      [toggleText]="'Laufzeiten einblenden'"
      (toggleChange)="toggleSwitch($event)"
      [useActiveInactive]="true"
      [activeText]="'Aktive Standorte'"
      [inactiveText]="'Inaktive Standorte'"
      [useTime]="false"
      [useRunTime]="true"
      [iconName]="'location-runtimes.svg'"
      [isRunTimeEnabled]="isAtLeastOneChecked$ | async"
      [addLink]="'/locations/location-administration'"
      [route]="route"
      [isActiveSelector]="isActiveSelector"
      [crudController]="crudController"
      [modalBoxComponentName]="LocationRuntimeModalBoxComponent"
      [useGrouping]="true"
    >
      <app-locations-table [isToggleSwitched]="isToggleSwitched"
                           [isEditable]="true"
                           [isBlue]="false"
                           [whenCheckboxChanged]="whenCheckboxChanged"
                           [onLocationSelected]="onLocationSelected">

      </app-locations-table>
    </app-table-page>`,
})
export class LocationsComponent {

  constructor(public crudController: LocationsController, public actionFactory: LocationsActionsCreator, private redux: NgRedux<IAppState>) {
    this.isActiveSelector = LocationsSelector.isActive;
    this.route = LocationsComponent.ROUTE;
  }

  public static ROUTE = LOCATIONS;

  @select(LocationsSelector.isAtLeastOneChecked) isAtLeastOneChecked$;
  public isToggleSwitched = false;
  @Input() whenCheckboxChanged: Function = _.noop;
  public route: string;
  public isActiveSelector: any;
  public name = LOCATIONS;
  public isRowChecked = false;
  public LocationRuntimeModalBoxComponent = LocationRuntimeModalBoxComponent;


  toggleSwitch(event: boolean) {
    this.isToggleSwitched = event;
  }

  public onLocationSelected(isChecked: boolean) {
    this.isRowChecked = isChecked;
  }
}

@Injectable()
export class LocationsDataResolver implements Resolve<any> {
  constructor(private redux: NgRedux<IAppState>, private locationsController: LocationsController) {
  }

  async resolve(): Promise<any> {
    await this.redux.dispatch(this.locationsController.updateActive());
  }
}
