import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {AuthApiService} from '../../../services/api/auth.api.service';
import {Router} from '@angular/router';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {UsersSelector} from '../../../redux/reducers/users.reducer';
import {UsersController} from '../../../redux/actions/users/users.controller';
import {IAppState} from '../../../redux/store';

@Component({
  selector: 'ba-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaNavbarComponent implements OnInit {

  @select(UsersSelector.getMe) user$: Observable<any>;

  constructor(private apiAuth: AuthApiService, private router: Router, private usersController: UsersController, private redux: NgRedux<IAppState>) {
  }

  async ngOnInit() {
    await this.redux.dispatch(this.usersController.updateMe());
  }

  async onLogout() {
    try {
      await this.apiAuth.logout();
      await this.router.navigate(['/login']);
    } catch (err) {
      console.warn('could not logout', err.message);
    }
  }
}
