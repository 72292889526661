import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import * as _ from 'lodash';
import {IApiGetLocationGroup, ILocationGroupModel} from '../../../../../../common/interfaces/locationGroup';
import {Observable, Subscription} from 'rxjs';
import {LocationsSelector} from '../../../redux/reducers/locations.reducer';
import {ModalService} from '../../../services/modal.service';
import {LocationGroupAddModalBoxComponent} from '../modal-box/location-group-add-modal-box/location-group-add-modal-box.component';
import {ToastrService} from 'ngx-toastr';
import {LocationGroupDeleteModalBoxComponent} from '../modal-box/location-group-delete-modal-box/location-group-delete-modal-box.component';
import {LocationGroupsService} from '../../../services/location-groups.service';
import {select} from '@angular-redux/store';

@Component({
  selector: 'ba-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class BaSearchFilterComponent implements OnInit, OnDestroy {

  public dropDownListItems: string[];
  public showToast = false;
  public currentGroups: IApiGetLocationGroup[];
  public bookingLocations: number[];
  public isItemSelected: EventEmitter<string> = new EventEmitter<string>();
  @Input() listItems$: Observable<IApiGetLocationGroup[]>;
  @Input() isDisabled: Boolean;
  @Input() bookingData?: any;
  @select(LocationsSelector.getCheckedIds) locationList$: Observable<number[]>;
  @select(LocationsSelector.getCheckedIds) checkedLocationList$: Observable<number[]>;
  private openFilter: boolean;
  private mySearch: string;
  private isValue: string;
  private isSelected: { currentFilter: string };
  private listener: any;
  private locationGroupListSubscription: Subscription;
  private locationListSubscription: Subscription;
  private locationListForEditSubscription: Subscription;
  private globalSelectedGroup: string;
  private haveBooking: Boolean;
  private inEditingMode: Boolean;

  constructor(
    private renderer: Renderer2,
    private eRef: ElementRef,
    protected modalService: ModalService,
    private toastrService: ToastrService,
    private locationGroupsService: LocationGroupsService,
  ) {
    this.openFilter = false;
    this.mySearch = null;
    this.isValue = null;
    this.isSelected = {currentFilter: null};
  }

  setLocationGroupsData() {
    this.locationGroupListSubscription = this.listItems$.subscribe(data => {
      this.currentGroups = data;
      this.dropDownListItems = data.map(locationGroupItem => locationGroupItem.group_name);
    });
    this.locationGroupListSubscription.unsubscribe();
  }

  getTheSelectedGroup(filter: string) {
    return _.find(
      this.currentGroups,
      location_group => location_group.group_name === filter,
    ) as ILocationGroupModel;
  }

  async ngOnInit() {
    await this.locationGroupsService.updateCurrentLocations();
    /* This events get called by all clicks outside of the component */
    this.listener = this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.eRef.nativeElement.contains(event.target)) {
        if (this.openFilter) {
          this.openFilter = !this.openFilter;
        } else if (this.locationGroupListSubscription) {
          this.locationGroupListSubscription.unsubscribe();
        } else if (this.locationListForEditSubscription) {
          this.locationListForEditSubscription.unsubscribe();
        }
      }
    });
    await this.initData();
    let isLocationGroupExists;
    if (this.bookingData && this.currentGroups) {
      isLocationGroupExists = this.currentGroups.find(element => {
        return element.group_name === this.bookingData.locations_group_name;
      });
    }
    if (!isLocationGroupExists) {
      this.isValue = null;
      this.isSelected = {currentFilter: null};
      this.isItemSelected.emit(this.isValue);
      this.locationGroupsService.setLocationGroupForBookings(null);
    }
    if (this.bookingData && this.bookingData.locations_group_name && isLocationGroupExists) {
      this.bookingLocations = this.bookingData.locations.map(x => x.id);
      await this.openSearchFilter();
      this.selectedSearchFilter(this.bookingData.locations_group_name);
    }
    if (this.bookingData && this.bookingData.locations && (!this.bookingData.locations_group_name || !isLocationGroupExists)) {
      await this.locationGroupsService.setLocationsOfTheGroup(this.bookingData.locations);
    }
  }

  async initData() {
    await this.locationGroupsService.initLocationGroupList();
    await this.setLocationGroupsData();
  }

  /* When the user clicks in the input,
  toggle between hiding and showing the dropdown content */
  async openSearchFilter() {
    await this.initData();
    this.openFilter = !this.openFilter;
  }

  /* When the user select an filter,
  toggle between showing and hiding the dropdown content
  and write selected item through input-field*/
  checkSearchEmpty(event) {
    if (this.mySearch) {
      // enable the button
      return this.mySearch;
    }
  }

  async hearForLocationListChange(groupName) {
    if (this.locationListForEditSubscription) {
      this.locationListForEditSubscription.unsubscribe();
    }
    const selectedGroup = this.getTheSelectedGroup(groupName);
    const locationsOfTheGroup = selectedGroup.locations;
    this.locationListForEditSubscription = await this.checkedLocationList$.subscribe(
      (checkedLocations: any) => {
        const isEqualArrays = _.isEqual(locationsOfTheGroup, checkedLocations);
        if (isEqualArrays) {
          this.showToast = true;
          if (this.inEditingMode) {
            this.isValue = null;
            this.isSelected = {currentFilter: null};
            this.isItemSelected.emit(this.isValue);
          } else {
            this.isValue = groupName;
            this.isSelected = {currentFilter: groupName};
            this.isItemSelected.emit(this.isValue);
          }
        } else {
          this.isValue = null;
          this.isSelected = {currentFilter: null};
          this.isItemSelected.emit(this.isValue);
          if (this.showToast && checkedLocations.length === 0) {
            checkedLocations = locationsOfTheGroup;
          }
          if (this.showToast && checkedLocations.length !== locationsOfTheGroup.length) {
            this.inEditingMode = true;
            this.locationGroupsService.setLocationGroupForBookings(null);
            this.toastrService.info('Die Standortauswahl wurde verändert.');
            this.showToast = false;
          }
        }
      },
    );
  }

  async selectedSearchFilter(filter) {
    this.inEditingMode = false;
    if (this.locationListForEditSubscription) {
      this.locationListForEditSubscription.unsubscribe();
    }
    if (this.locationListSubscription) {
      this.locationListSubscription.unsubscribe();
    }
    if (this.isSelected.currentFilter && this.bookingData) {
      this.haveBooking = true;
      this.removeSelected();
    }
    if (this.isSelected.currentFilter) {
      this.removeSelected();
    }
    if (this.bookingData && this.bookingData.locations && !this.bookingData.locations_group_name) {
      await this.locationGroupsService.updateCurrentLocations();
    }
    if (this.bookingData) {
      this.locationGroupsService.setLocationGroupForBookings(filter);
    }
    this.showToast = false;
    this.hearForLocationListChange(filter);
    this.showToast = false;
    if (this.locationListForEditSubscription) {
      this.locationListForEditSubscription.unsubscribe();
    }
    /* set selected item as result to input value */
    this.isValue = filter;
    /* reset search input */
    this.mySearch = null;
    /* set current selected item active */
    this.isSelected = {currentFilter: filter};
    /* close list */
    this.openFilter = !this.openFilter;

    this.globalSelectedGroup = filter;

    this.isItemSelected.emit(this.isValue);
    const selectedGroup = this.getTheSelectedGroup(filter);
    const locationsOfTheGroup = selectedGroup.locations;
    const idOflocationGroup = selectedGroup.id;
    await this.locationGroupsService.setTheCheckedStatusOFTheGroup(
      idOflocationGroup,
      locationsOfTheGroup,
    );
  }

  removeSelected() {
    if (this.bookingData) {
      this.haveBooking = true;
    }
    if (this.locationListForEditSubscription) {
      this.locationListForEditSubscription.unsubscribe();
    }
    if (this.locationListSubscription) {
      this.locationListSubscription.unsubscribe();
    }
    const localSelectedGroup = this.globalSelectedGroup;
    if (localSelectedGroup) {
      const selectedGroup = _.find(
        this.currentGroups,
        location_group => location_group.group_name === localSelectedGroup,
      ) as ILocationGroupModel;
      const locationsOfTheGroup = selectedGroup.locations;
      const idOflocationGroup = selectedGroup.id;
      this.locationGroupsService.setLocationGroupForBookings(null);
      if (this.bookingData && !this.haveBooking) {
        this.haveBooking = false;
        this.locationGroupsService.removeSelectedWithoutReset(
          idOflocationGroup,
          locationsOfTheGroup,
        );
      } else {
        this.locationGroupsService.removeSelectedWithReset(
          idOflocationGroup,
          locationsOfTheGroup,
        );
      }
      this.isValue = null;
      this.isSelected = {currentFilter: null};
      this.isItemSelected.emit(this.isValue);
    }
  }

  ngOnDestroy() {
    /* Remove the listener */
    this.listener();
    if (this.locationGroupListSubscription) {
      this.locationGroupListSubscription.unsubscribe();
    }
    if (this.locationListSubscription) {
      this.locationListSubscription.unsubscribe();
    }
    if (this.locationListForEditSubscription) {
      this.locationListForEditSubscription.unsubscribe();
    }
  }

  async addLocationGroupModalBox() {
    if (this.locationListForEditSubscription) {
      this.locationListForEditSubscription.unsubscribe();
    }
    let selectedLocations: number[] = [];
    this.locationListSubscription = this.locationList$.subscribe(
      data => (selectedLocations = data),
    );
    if (selectedLocations.length === 0) {
      this.toastrService.error('Bitte wählen Sie die Standorte.');
      return;
    }
    this.openFilter = false;
    this.isSelected = {currentFilter: null};
    this.modalService.open(LocationGroupAddModalBoxComponent, {
      isMobile: false,
      onSubmit: async () => {
        if (this.bookingData) {
          await this.openSearchFilter();
          this.selectedSearchFilter(this.locationGroupsService.getNewLocationGroup());
        } else {
          this.removeSelected();
        }

      },
    });
    this.locationListSubscription.unsubscribe();
  }

  async saveLocationGroup(filter: string) {
    if (this.locationListForEditSubscription) {
      this.locationListForEditSubscription.unsubscribe();
    }
    const selectedGroup = this.getTheSelectedGroup(filter);
    const locationGroupId = selectedGroup.id;
    let selectedLocations: number[] = [];
    this.locationListSubscription = this.locationList$.subscribe(
      data => (selectedLocations = data),
    );
    const updatedGroup = {...selectedGroup, locations: selectedLocations};
    await this.locationGroupsService.updateLocationGroup(
      locationGroupId,
      updatedGroup,
    );
    this.locationListSubscription.unsubscribe();
    if (this.bookingData) {
      await this.initData();
      await this.selectedSearchFilter(filter);
    } else {
      this.removeSelected();
      this.locationGroupsService.removeSelectedLocationsOfTheGroup(
        selectedLocations,
      );
      this.openFilter = !this.openFilter;
    }
    this.toastrService.success('Die Gruppe wurde erfolgreich gespeichert.');
    if (this.locationListForEditSubscription) {
      this.locationListForEditSubscription.unsubscribe();
    }
  }

  async deleteLocationGroupModalBox(filter: string) {
    this.openFilter = false;
    this.modalService.open(LocationGroupDeleteModalBoxComponent, {
      title: filter,
      isMobile: false,
      onSubmit: async () => {
        if (this.isSelected.currentFilter === filter) {
          this.removeSelected();
        }
        await this.delete(filter);
      },
    });
  }

  async delete(filter: string) {
    const selectedGroup = this.getTheSelectedGroup(filter);
    const locationGroupId = selectedGroup.id;
    await this.locationGroupsService.deleteLocationGroup(locationGroupId);
  }
}
