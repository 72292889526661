import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IWeekday} from '../../../../../../common/interfaces/location';
import weekDayEnumItems from '../../../../../../common/weekdays';

@Component({
  selector: 'ba-runtime',
  templateUrl: './runtime.component.html',
  styleUrls: ['./runtime.component.scss'],
})
export class BaRuntimeComponent {

  @Input() index: number;
  @Input() isDeletable = true;
  @Output() remove = new EventEmitter<number>();

  @Input() startDay: IWeekday;
  @Output() startDayChange = new EventEmitter<IWeekday>();

  @Input() endDay: IWeekday;
  @Output() endDayChange = new EventEmitter<IWeekday>();

  @Input() startTime: string;
  @Output() startTimeChange = new EventEmitter<string>();

  @Input() endTime: string;
  @Output() endTimeChange = new EventEmitter<string>();

  public days = weekDayEnumItems;

  onStartDayChange(event: IWeekday) {
    this.startDayChange.emit(event);
  }

  onEndDayChange(event: IWeekday) {
    this.endDayChange.emit(event);
  }

  onStartTimeChange(event: string) {
    this.startTimeChange.emit(event);
  }

  onEndTimeChange(event: string) {
    this.endTimeChange.emit(event);
  }

  removeObject(e) {
    e.stopPropagation();
    e.preventDefault();

    this.remove.emit(this.index);
  }

}
