import {
  IBookingLogItem,
  ILocation,
  ILogItem,
} from './interfaces';

export class LogsService {
  private static instance: LogsService;
  private logsToPush: Array<ILogItem> = [];
  private location: ILocation;

  static getInstance() {
    if (!LogsService.instance) {
      LogsService.instance = new LogsService();
    }

    return LogsService.instance;
  }

  public setLocation(location: ILocation) {
    this.location = location;
  }

  public addLog(bookingLog: IBookingLogItem) {
    const bookingData = {
      booking: {
        id: bookingLog.bookingId,
        duration: bookingLog.bookingDuration,
        files: bookingLog.bookingFiles,
        inputs: bookingLog.bookingInputs,
        name: bookingLog.bookingName,
      },
      location: this.location,
      bookingTemplate: bookingLog.bookingTemplate,
    };

    this.logsToPush.push({
      type: bookingLog.type,
      timestamp: bookingLog.timestamp,
      booking_id: bookingLog.bookingId,
      data: JSON.stringify(bookingData),
      location_id: this.location.id,
      booking_template_id: bookingLog.bookingTemplate.id,
    });
  }

  public getLogsToSave() {
    const currentLogs = this.logsToPush;
    this.logsToPush = this.logsToPush.slice(currentLogs.length);

    return currentLogs;
  }

  public clearLogsList() {
    this.logsToPush = [];
  }
}
