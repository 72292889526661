import {DOCUMENT} from '@angular/common';
import {Component, Inject, Injectable, OnDestroy, OnInit, Renderer2} from '@angular/core';
import * as _ from 'lodash';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../../../../redux/store';
import {BookingsSelector, IStateBooking} from '../../../../redux/reducers/bookings.reducer';
import {formatSeconds, formatTime} from '../../../../../../../common/moment-util';
import {FileApiService} from '../../../../services/api/file.api.service';
import {IStateLocation, LocationsSelector} from '../../../../redux/reducers/locations.reducer';
import {getPlaylistAdministrationRoute, PLAYLIST_ADMINISTRATION_ROUTE, PLAYLIST_ROUTE} from '../playlist-routes';
import {LocationsController} from '../../../../redux/actions/locations/locations.controller';
import {BookingsController} from '../../../../redux/actions/bookings/bookings.controller';
import {LocationApiService} from '../../../../services/api/location.api.service';
import {ID} from '../../../../../../../common/constants';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ModalService} from '../../../../services/modal.service';
import {PlaylistType} from '../../../../../../../common/interfaces/playlist';
import {WarningDiscardModalBoxComponent} from '../../../ui-elements/modal-box/warning-discard-modal-box/warning-discard-modal-box.component';
// tslint:disable-next-line:max-line-length
import {PlaylistChangeToRandomModalBoxComponent} from '../../../ui-elements/modal-box/playlist-change-to-random-modal-box/playlist-change-to-random-modal-box.component';
// tslint:disable-next-line:max-line-length
import {PlaylistChangeToCustomModalBoxComponent} from '../../../ui-elements/modal-box/playlist-change-to-custom-modal-box/playlist-change-to-custom-modal-box.component';
import {LocationsActionsCreator} from '../../../../redux/actions/locations/locations.action';
import {ToastrService} from 'ngx-toastr';
import { DisplayMode } from '../../../../../../../common/interfaces/booking';

interface BookingType {
  listName: string;
  bookingsName: string;
  bookings: Array<IStateBooking>;
  totalRuntime: string;
}

@Component({
  selector: 'app-playlist-administration',
  templateUrl: './playlist-administration.component.html',
  styleUrls: ['./playlist-administration.component.scss'],
})
export class PlaylistAdministrationComponent implements OnInit, OnDestroy {

  public static ROUTE = PLAYLIST_ADMINISTRATION_ROUTE;

  private routeSubscription: Subscription;
  private bookingsSubscription: Subscription;
  private locationsSubscription: Subscription;

  @select(BookingsSelector.getAll) bookings$: Observable<IStateBooking[]>;
  @select(LocationsSelector.getAll) locations$: Observable<IStateLocation[]>;

  public locationId: number;
  public bookingTypes: Array<BookingType> = [];
  public bookings: IStateBooking[] = [];
  public priorityBookings: IStateBooking[] = [];
  public standardBookings: IStateBooking[] = [];
  public fallbackBookings: IStateBooking[] = [];
  public allBookings: IStateBooking[] = [];
  public locations: IStateLocation[];
  public isDraggable = false;
  private isChanged = false;
  private isSaving = false;
  private wasDraggable = false;
  private onClose: Function = _.noop;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected toastrService: ToastrService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private redux: NgRedux<IAppState>,
    private locationsActionCreator: LocationsActionsCreator,
    private fileApiService: FileApiService,
    private locationApiService: LocationApiService,
    protected modalService: ModalService,
  ) {
  }

  public onLocationChange(event: any) {
    return this.router.navigate([getPlaylistAdministrationRoute(event.target.value)]);
  }

  private formatClockTime(clockTime: string) {
    return clockTime ? formatTime(clockTime) : '';
  }

  private initLocations(locations: IStateLocation[]) {
    this.locations = _.chain(locations)
      .map((location) => {
        const isSelected = _.parseInt(location.id as any) === this.locationId;

        if (isSelected) {
          this.isDraggable = location.playlist_type === PlaylistType.custom;
          this.wasDraggable = this.isDraggable;
        }

        return Object.assign({}, location, {selected: isSelected});
      })
      .sortBy(ID)
      .value();
  }

  private initBookings(bookings: IStateBooking[]) {
    const preparedBookings = _.chain(bookings)
      .filter((booking) => !!_.find(booking.locations, [ID, this.locationId]))
      .map((booking) => Object.assign({}, booking, {
        thumbnail_url: this.fileApiService.getAuthorizedFileUrl(_.get(booking, 'thumbnail_url') || _.get(booking, 'files[0].thumbnail_url', '')),
        durationFormatted: formatSeconds(booking.duration),
        start_timeFormatted: this.formatClockTime(booking.start_time),
        end_timeFormatted: this.formatClockTime(booking.end_time),
      }))
      .value();

    const bookingsMap = _.keyBy(preparedBookings, ID);

    this.bookings = _.chain(this.route)
      .get('snapshot.data.shuffledBookingIds', [])
      .map((bookingId) => bookingsMap[bookingId])
      .compact()
      .value();

    this.priorityBookings = this.bookings.filter(booking => booking.display_mode === DisplayMode.Priority);
    this.fallbackBookings = this.bookings.filter(booking => booking.display_mode === DisplayMode.Fallback);
    this.standardBookings = this.bookings.filter(booking => booking.display_mode === DisplayMode.Standard);

    this.bookingTypes = [
      {
        listName: 'Prioritätsplayliste',
        bookingsName: 'Prioritätsbuchungen',
        bookings: this.priorityBookings,
        totalRuntime: formatSeconds(_.sumBy(this.priorityBookings, 'duration')),
      },
      {
        listName: 'Standardplayliste',
        bookingsName: 'Standardbuchungen',
        bookings: this.standardBookings,
        totalRuntime: formatSeconds(_.sumBy(this.standardBookings, 'duration')),
      },
      {
        listName: 'Ersatzmedien Playliste',
        bookingsName: 'Ersatzmedien Buchungen',
        bookings: this.fallbackBookings,
        totalRuntime: formatSeconds(_.sumBy(this.fallbackBookings, 'duration')),
      },
    ];

  }

  drop(event: CdkDragDrop<string[]>, bookings) {
    if (!this.isDraggable) {
      return;
    }

    this.isChanged = true;

    moveItemInArray(bookings, event.previousIndex, event.currentIndex);
  }

  public enableDrag() {
    if (this.isDraggable) {
      return;
    }

    this.modalService.open(PlaylistChangeToCustomModalBoxComponent, {
      isMobile: false,
      onSubmit: () => {
        this.isChanged = true;
        this.isDraggable = true;
      },
    });
  }

  public disableDrag() {
    if (!this.isDraggable) {
      return;
    }

    this.modalService.open(PlaylistChangeToRandomModalBoxComponent, {
      isMobile: false,
      onSubmit: async () => {
        await this.savePlaylist(false);
        const playlist = await this.locationApiService.getPlayList(this.locationId);

        this.isChanged = true;
        this.isDraggable = false;
        this.isChanged = false;

        const bookingsMap = _.keyBy(this.bookings, ID);
        this.bookings = _.compact(_.map(playlist, (bookingId) => bookingsMap[bookingId]));
      },
    });
  }

  public async savePlaylist(isDraggable = this.isDraggable) {
    if (this.isSaving) {
      return;
    }

    this.isChanged = false;
    this.isSaving = true;
    this.allBookings = _.concat(this.priorityBookings, this.bookings);

    try {
      const playlist_type = isDraggable ? PlaylistType.custom : PlaylistType.shuffle;
      await Promise.all([
        isDraggable ? this.locationApiService.setCustomBookings(this.locationId.toString(), _.map([
          ...this.priorityBookings,
          ...this.standardBookings,
          ...this.fallbackBookings,
        ], ID)) : Promise.resolve(),
        this.locationApiService.update(this.locationId, {playlist_type}),
        isDraggable ? this.toastrService.info('Die Playliste wird gespeichert.') : Promise.resolve(),
    ]);

      this.onClose = () => this.redux.dispatch(this.locationsActionCreator.update({id: this.locationId, playlist_type}));

      if (isDraggable) { this.toastrService.success('Die Playliste wurde erfolgreich gespeichert.'); }

    } catch (err) {
      this.isChanged = true;
      this.toastrService.error('Die Playliste konnte nicht gespeichert werden. Bitte versuchen Sie es nocheinmal.');
      throw err;
    } finally {
      this.isSaving = false;
    }
  }

  public initCancelModal() {
    const onSubmit = async () => {
      await this.router.navigate([PLAYLIST_ROUTE]);
      await this.onClose();
    };

    if (!this.isChanged) {
      return onSubmit();
    }

    this.modalService.open(WarningDiscardModalBoxComponent, {isMobile: false, onSubmit});
  }

  ngOnInit() {
    this.renderer.addClass(this.document.body, 'overlay');

    this.routeSubscription = this.route.params.subscribe(({id}) => this.locationId = _.parseInt(id));
    this.locationsSubscription = this.locations$.subscribe((locations) => this.initLocations(locations));
    this.bookingsSubscription = this.bookings$.subscribe((bookings) => this.initBookings(bookings));

    return this.bookingsSubscription;
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'overlay');

    this.routeSubscription.unsubscribe();
    this.locationsSubscription.unsubscribe();
    this.bookingsSubscription.unsubscribe();
  }
}

@Injectable()
export class PlaylistLocationDataResolver implements Resolve<any> {
  constructor(
    private redux: NgRedux<IAppState>,
    private locationApiService: LocationApiService,
    private locationsController: LocationsController,
    private bookingsController: BookingsController,
  ) {
  }

  async resolve(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const results = await Promise.all([
      this.locationApiService.getPlayList(routeSnapshot.params.id),
      this.redux.dispatch(this.locationsController.updateActive()),
      this.redux.dispatch(this.bookingsController.updateActive()),
    ]);

    return results[0];
  }
}
