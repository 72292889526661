import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {full} from '../../../../../common/routes';
import {AbstractCrudApiService} from './abstract-crud.api.service';
import {AuthApiService} from './auth.api.service';
import {ApiService} from './api.service';

@Injectable()
export class LocationApiService extends AbstractCrudApiService {

  protected apiUrl: string = full.location;

  constructor(http: HttpClient, authApi: AuthApiService, api: ApiService) {
    super(http, authApi, api);
  }

  public getPlayList(locationId: string | number): Promise<number[]> {
    return this.api.get(this.insertId(full.locationPlaylist, locationId));
  }

  public setCustomBookings(locationId: string, customIds: number[]) {
    return this.api.put(this.insertId(full.locationPlaylist, locationId), customIds);
  }

  public getLastUpdated(locationId: string) {
    return this.api.get(this.insertId(full.locationLastupdated, locationId), {responseType: 'text'});
  }
}
