import {
  Component,
  Injectable,
} from '@angular/core';
import { IDeleteTexts } from '../../../services/modal.service';
import { IColumn } from '../table-page/table/table.component';
import { ExternalResourcesController } from '../../../redux/actions/external-resources/external-resources.controller';
import { ExternalResourcesActionsCreator } from '../../../redux/actions/external-resources/external-resources.action';
import { IStateExternalResource, ExternalResourcesSelector } from '../../../redux/reducers/external-resources.reducer';
import { IAppState } from '../../../redux/store';
import { Resolve } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { LocationsController } from 'src/app/redux/actions/locations/locations.controller';
import {
  EXTERNAL_RESOURCES,
  EXTERNAL_RESOURCES_PROTOCOLS,
} from '../../../../../../common/constants';

@Component({
  selector: 'app-external-resources',
  template: `
    <app-table-page
      [name]="name"
      [title]="'Schnittstellenverwaltung'"
      [useToggle]="false"
      [useActiveInactive]="false"
      [useTime]="false"
      [useRunTime]="false"
      [addLink]="'/external-resources/external-resource-administration'"
      [route]="route">
      <app-table
        [deleteTexts]="deleteTexts"
        [isToggleSwitched]="false"
        [name]="name"
        [editOrAddLinkGetter]="editOrAddLinkGetter"
        [columns]="columns"
        [getAllSelector]="getAll"
        [getAllIdsSelector]="getAllIds"
        [isAllCheckedSelector]="isAllChecked"
        [getFilterByNameSelector]="getFilterByName"
        [actionFactory]="actionFactory"
        [crudController]="crudController"
      >
      </app-table>
    </app-table-page>`,
})
export class ExternalResourcesComponent {

  public static ROUTE = EXTERNAL_RESOURCES;
  public name = 'externalResources';
  public route: string;
  public getAll: ($state: IAppState) => IStateExternalResource[];
  public getAllIds: ($state: IAppState) => string[];
  public isAllChecked: ($state: IAppState) => boolean;
  public getFilterByName: (name: string) => ($state: IAppState) => any;

  constructor(public crudController: ExternalResourcesController, public actionFactory: ExternalResourcesActionsCreator) {
    this.route = ExternalResourcesComponent.ROUTE;

    this.getAll = ExternalResourcesSelector.getAll;
    this.getAllIds = ExternalResourcesSelector.getAllIds;
    this.isAllChecked = ExternalResourcesSelector.isAllChecked;
    this.getFilterByName = ExternalResourcesSelector.getFilterByName;
  }

  public deleteTexts: IDeleteTexts = {
    delete: 'API löschen',
    reallyDelete: 'Möchtest Du die ausgewählte API wirklich löschen?',
    noKeep: 'Nein, API behalten.',
    yesDelete: 'Ja, API löschen.',
  };

  public columns: IColumn[] = [
    {
      name: 'API Name',
      filterName: 'name',
      cellClass: 'large',
      dataGetter: (data) => data.name,
    },

    {
      name: 'Beschreibung',
      filterName: 'description',
      cellClass: 'large',
      dataGetter: (data) => data.description,
    },
    {
      name: 'Protokoll',
      filterName: 'protocol',
      cellClass: 'medium',
      dataGetter: (data) => {
        const option = EXTERNAL_RESOURCES_PROTOCOLS.find(({ code }) => code === data.protocol);
        return option
          ? option.name
          : data.protocol;
      },
    },
  ];

  public editOrAddLinkGetter(rowData: any) {
    return `/external-resources/external-resource-administration/${rowData.id}`;
  }

}

@Injectable()
export class ExternalResourcesDataResolver implements Resolve<any> {
  constructor(
    private redux: NgRedux<IAppState>,
    private externalResoucesController: ExternalResourcesController,
    private locationsController: LocationsController,
  ) {}

  async resolve(): Promise<any> {
    await this.redux.dispatch(this.locationsController.updateActive());
    await this.redux.dispatch(this.externalResoucesController.updateActive());

    return true;
  }
}

