import {OnDestroy, OnInit, Renderer2} from '@angular/core';
import {WarningDiscardModalBoxComponent} from '../../ui-elements/modal-box/warning-discard-modal-box/warning-discard-modal-box.component';
import {ModalService} from '../../../services/modal.service';
import {DuplicateTrackingService} from '../../../services/duplicate-tracking-service';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../../../redux/store';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {AbstractCrudController} from '../../../redux/actions/abstractCrud.controller';
import * as _ from 'lodash';
import {AbstractCrudApiService} from '../../../services/api/abstract-crud.api.service';
import {Subscription} from 'rxjs';

export interface IAdministrationValidator {
  validate: () => boolean;
  errorMessage: string;
}

export abstract class AdministrationPageComponent implements OnInit, OnDestroy {

  public isSubmitted = false;
  public isInvalidForm = false;

  protected id: number | string;
  protected useFormData = false;
  protected successMessage = 'Ihre Änderungen wurden gespeichert';

  protected abstract validators: IAdministrationValidator[] = [];
  protected abstract onCloseRoute: string;

  protected abstract renderer: Renderer2;
  protected abstract redux: NgRedux<IAppState>;
  protected abstract toastrService: ToastrService;
  protected abstract router: Router;
  protected abstract modalService: ModalService;
  protected abstract duplicateTrackingService: DuplicateTrackingService;
  protected abstract controller: AbstractCrudController<any>;
  protected abstract apiService: AbstractCrudApiService;

  protected abstract document: Document;

  public abstract model: any;
  public wasFormModified: boolean;

  private paramsSub: Subscription;
  public boundOnFormChange: Function;

  protected constructor() {
    this.boundOnFormChange = this.onFormChange.bind(this);
  }

  protected abstract getParamsSub(): Subscription;

  public ngOnInit() {
    this.renderer.addClass(this.document.body, 'overlay');
    this.paramsSub = this.getParamsSub();
    this.wasFormModified = false;
  }

  public ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'overlay');
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }

  }

  public onFormChange() {
    this.wasFormModified = true;
  }

  public async initCancelModal() {
    if (this.wasFormModified) {
      const modalOnSubmit = async () => {
        this.router.navigate([this.onCloseRoute]);
        await this.redux.dispatch(this.controller.updateCurrent());
      };
      return this.modalService.open(WarningDiscardModalBoxComponent, {isMobile: false, onSubmit: modalOnSubmit});
    }
    await this.redux.dispatch(this.controller.updateCurrent());
    return this.router.navigate([this.onCloseRoute]);
  }

  protected async prepareModel() {
  }

  protected async preSaveHook(): Promise<any> {
  }

  protected abstract isModelValid(): boolean;

  protected onError(preSaveHookResult: any) {
    this.toastrService.error('', 'Es gab ein Problem beim Speichern. Bitte versuche es noch einmal.');
  }

  protected onConflict(preSaveHookResult: any) {
    this.onError(preSaveHookResult);
  }

  protected async onSuccess(response: Response, preSaveHookResult: any) {
    await this.redux.dispatch(this.controller.updateCurrent());
    await this.router.navigate([this.onCloseRoute]);
    this.toastrService.success('', this.successMessage);
  }

  public async onSubmit() {
    if (this.isSubmitted) {
      return;
    }
    this.isSubmitted = true;

    this.isInvalidForm = false;

    if (!this.isModelValid()) {
      this.toastrService.error('', 'Felder mit * sind Pflichtfelder.');
      this.isInvalidForm = true;
    }

    _.each(this.validators, (validator) => {
      if (!validator.validate()) {
        this.toastrService.error('', validator.errorMessage);
        this.isInvalidForm = true;
      }
    });

    if (this.isInvalidForm) {
      this.isSubmitted = false;
      return;
    }

    const preSaveHookResult = await this.preSaveHook();

    await this.prepareModel();

    let response: any;

    try {
      if (this.id) {
        response = await this.apiService.update(this.id, this.model, this.useFormData);
      } else {
        response = await this.apiService.add(this.model, this.useFormData);
      }
    } catch (err) {
      if (err.status === 409) {
        this.isSubmitted = false;
        return this.onConflict(preSaveHookResult);
      }

      console.error('error when saving/updating', err);
      this.isSubmitted = false;
      return this.onError(preSaveHookResult);
    }

    await this.onSuccess(response, preSaveHookResult);
    this.duplicateTrackingService.setDuplicate(false);
    this.isSubmitted = false;
  }

}
