import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './auth-handling/auth.guard';
import {LoginComponent} from './components/core/login/login.component';
import {DashboardComponent, NavbarDataResolver} from './components/core/dashboard/dashboard.component';
import {NotFoundComponent} from './components/core/not-found/not-found.component';
import {BookingsComponent, BookingsDataResolver} from './components/core/bookings/bookings.component';
import {BookingTemplatesComponent} from './components/core/bookings/booking-templates/booking-templates.component';
import {
  BookingAdministrationComponent,
  BookingFormDataResolver,
} from './components/core/bookings/booking-administration/booking-administration.component';
import {LocationsComponent, LocationsDataResolver} from './components/core/locations/locations.component';
import {
  LocationsAdministrationComponent,
  LocationsAdministrationDataResolver,
} from './components/core/locations/locations-administration/locations-administration.component';

import {PlaylistsComponent} from './components/core/playlists/playlists.component';
import {
  PlaylistAdministrationComponent,
  PlaylistLocationDataResolver,
} from './components/core/playlists/playlist-administration/playlist-administration.component';

import {PreviewsComponent} from './components/core/previews/previews.component';
import {PreviewLocationComponent, PreviewLocationDataResolver} from './components/core/previews/preview-location/preview-location.component';
import {UsersComponent, UsersDataResolver} from './components/core/users/users.component';
import {
  UsersAdministrationComponent,
  UsersAdministrationDataResolver,
} from './components/core/users/users-administration/users-administration.component';
import {RolesDataResolver, RolesRightComponent} from './components/core/roles-right/roles-right.component';
import {RolesRightAdministrationComponent} from './components/core/roles-right/roles-right-administration/roles-right-administration.component';
import {BookingTemplatesDataResolver} from './components/core/bookings/booking-templates/booking-templates-list/booking-templates-list.component';
import {FULL} from '../../../common/constants';
import {FullPreviewComponent} from './components/core/previews/full-preview/full-preview.component';
import {MediasComponent, MediasDataResolver} from './components/core/medias/medias.component';
import {
  ExternalResourcesComponent,
  ExternalResourcesDataResolver,
} from './components/core/external-resources/external-resources.component';
import {
  ExternalResourcesAdministrationComponent,
} from './components/core/external-resources/external-resources-administration/external-resources-administration.component';
import {
  SettingsComponent,
  SettingsDataResolver,
} from './components/core/settings/settings.component';
import {
  CategoriesComponent,
  CategoriesDataResolver,
} from './components/core/categories/categories.component';

const appRoutes: Routes = [{
  path: '', canActivate: [AuthGuard], children: [
    {path: '', redirectTo: DashboardComponent.ROUTE, pathMatch: FULL},
    {path: 'login', component: LoginComponent},
    {path: DashboardComponent.ROUTE, component: DashboardComponent, resolve: {_: NavbarDataResolver}},
    {
      path: UsersComponent.ROUTE, component: UsersComponent, resolve: {_: UsersDataResolver},
      children: [
        {
          path: UsersAdministrationComponent.ROUTE,
          component: UsersAdministrationComponent,
          resolve: {_: LocationsDataResolver, __: BookingTemplatesDataResolver, roles: UsersAdministrationDataResolver},
        },
        {
          path: `${UsersAdministrationComponent.ROUTE}/:id`,
          component: UsersAdministrationComponent,
          resolve: {_: LocationsDataResolver, __: BookingTemplatesDataResolver, ___: UsersDataResolver, roles: UsersAdministrationDataResolver},
        },
      ],
    },
    {
      path: BookingsComponent.ROUTE, component: BookingsComponent, resolve: {_: BookingsDataResolver},
      children: [
        {path: BookingTemplatesComponent.ROUTE, component: BookingTemplatesComponent, resolve: {_: BookingTemplatesDataResolver}},
        {
          path: `${BookingAdministrationComponent.ROUTE}/:bookingTemplateId`,
          component: BookingAdministrationComponent,
          resolve: {_: BookingFormDataResolver},
        },
        {
          path: `${BookingAdministrationComponent.ROUTE}/:bookingTemplateId/:bookingId`,
          component: BookingAdministrationComponent,
          resolve: {_: BookingFormDataResolver},
        },
      ],
    },
    {
      path: PlaylistsComponent.ROUTE, component: PlaylistsComponent, resolve: {_: LocationsDataResolver},
      children: [
        {
          path: `${PlaylistAdministrationComponent.ROUTE}/:id`,
          component: PlaylistAdministrationComponent,
          resolve: {shuffledBookingIds: PlaylistLocationDataResolver},
        },
      ],
    },
    {
      path: PreviewsComponent.ROUTE, component: PreviewsComponent, resolve: {_: LocationsDataResolver},
      children: [
        {
          path: `${PreviewLocationComponent.ROUTE}/:id`,
          component: PreviewLocationComponent,
          resolve: {shuffledBookingIds: PreviewLocationDataResolver},
        },
      ],
    },
    {path: FullPreviewComponent.ROUTE, component: FullPreviewComponent},
    {
      path: LocationsComponent.ROUTE, component: LocationsComponent, resolve: {_: LocationsDataResolver},
      children: [
        {
          path: LocationsAdministrationComponent.ROUTE,
          component: LocationsAdministrationComponent,
          resolve: {_: LocationsAdministrationDataResolver},
        },
        {
          path: `${LocationsAdministrationComponent.ROUTE}/:id`,
          component: LocationsAdministrationComponent,
          resolve: {_: LocationsAdministrationDataResolver},
        },
      ],
    },
    {
      path: RolesRightComponent.ROUTE, component: RolesRightComponent, resolve: {_: RolesDataResolver},
      children: [
        {
          path: RolesRightAdministrationComponent.ROUTE,
          component: RolesRightAdministrationComponent,
        },
        {
          path: `${RolesRightAdministrationComponent.ROUTE}/:id`,
          component: RolesRightAdministrationComponent,
        },
      ],
    },
    {
      path: MediasComponent.ROUTE, component: MediasComponent, resolve: {_: MediasDataResolver},
    },
    {
      path: ExternalResourcesComponent.ROUTE,
      component: ExternalResourcesComponent,
      resolve: {_: ExternalResourcesDataResolver},
      children: [
        {
          path: ExternalResourcesAdministrationComponent.ROUTE,
          component: ExternalResourcesAdministrationComponent,
        },
        {
          path: `${ExternalResourcesAdministrationComponent.ROUTE}/:id`,
          component: ExternalResourcesAdministrationComponent,
        },
      ],
    },
    {
      path: SettingsComponent.ROUTE,
      component: SettingsComponent,
      resolve: {_: SettingsDataResolver},
    },
    {
      path: CategoriesComponent.ROUTE,
      component: CategoriesComponent,
      resolve: {_: CategoriesDataResolver},
    },
    {path: '404', component: NotFoundComponent},
    {path: '**', redirectTo: '404'},
  ],
}];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash: false, initialNavigation: 'enabled'})],
  providers: [
    NavbarDataResolver,
    RolesDataResolver,
    PlaylistLocationDataResolver,
    PreviewLocationDataResolver,
    BookingFormDataResolver,
    LocationsDataResolver,
    LocationsAdministrationDataResolver,
    BookingsDataResolver,
    BookingTemplatesDataResolver,
    UsersDataResolver,
    UsersAdministrationDataResolver,
    MediasDataResolver,
    ExternalResourcesDataResolver,
    SettingsDataResolver,
    CategoriesDataResolver,
  ],
  exports: [RouterModule],
})

export class AppRoutingModule {}
