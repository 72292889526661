import { Model } from 'sequelize';
import { IId } from './util';
import {
  IApiGetLocation,
  LocationId,
} from './location';

interface IApiPlaylistBase {
  bookings: number[];
}

export interface IApiSetPlaylist extends IApiPlaylistBase {
  location: number;
}

export interface IApiGetPlaylist extends IApiPlaylistBase, IId {
  location: IApiGetLocation;
}

export enum PlaylistType {
  shuffle = 'shuffle',
  custom = 'custom',
}

export type PlaylistId = IId['id'];

export interface IPlaylistModel extends Model {
  id: PlaylistId;
  bookings: Array<number>;
  location: LocationId;
}
