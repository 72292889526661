import * as _ from 'lodash';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as keycode from 'keycode';
import { ALLOWED_TYPES } from '../../../../../../common/constants';

const offCanvasComponents: Set<BaOffCanvasComponent> = new Set();

window.addEventListener('keyup', _.debounce((event: KeyboardEvent) => {
  offCanvasComponents.forEach((offCanvasComponent) => offCanvasComponent.keyEvent(event));
}, 1000));

window.addEventListener('click', _.debounce((event: Event) => {
  offCanvasComponents.forEach((offCanvasComponent) => offCanvasComponent.handleClick(event));
}, 1000));

@Component({
  selector: 'ba-off-canvas',
  templateUrl: './off-canvas.component.html',
  styleUrls: ['./off-canvas.component.scss'],
})
export class BaOffCanvasComponent implements OnDestroy, OnInit {

  @Input() onDelete: (event: any) => any;
  @Input() onEdit: (event: any) => any;
  @Input() onDuplicate: (event: any) => any;
  @Input() useDelete = true;
  @Input() useDuplicate: boolean;
  @Input() useEdit = true;
  @Input() useReplace = false;
  @Input() elementId: number;
  @Input() downloadLink: string;

  @Output() handleReplace = new EventEmitter<any>();

  public _toggleMenu = false;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    offCanvasComponents.add(this);
  }

  private toggleIfSetAnd(shouldToggle: boolean) {
    if (this._toggleMenu && shouldToggle) {
      this.toggleMenu();
    }
  }

  keyEvent(event: KeyboardEvent) {
    // tslint:disable
    this.toggleIfSetAnd(event.keyCode === keycode.aliases.escape);
  }

  handleClick(event: Event) {
    this.toggleIfSetAnd(!this.elementRef.nativeElement.contains(event.target));
  }

  toggleMenu() {
    this._toggleMenu = !this._toggleMenu;
  }

  onReplaceFilePick(event: Event) {
    this.handleReplace.emit(event);
  }

  get allowedMimeTypes(): string {
    return ALLOWED_TYPES.join(',');
  }

  ngOnDestroy(): void {
    offCanvasComponents.delete(this);
  }
}
