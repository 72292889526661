import {ToastrService} from 'ngx-toastr';
import {flatMap} from 'rxjs/operators';

import {ActivatedRoute, Router} from '@angular/router';
import {Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {NgRedux} from '@angular-redux/store';

import {AdministrationPageComponent} from '../../administration-page/administration-page.component';
import {IAppState} from '../../../../redux/store';
import {ModalService} from '../../../../services/modal.service';
import {RolesController} from '../../../../redux/actions/roles/roles.controller';
import {RoleApiService} from '../../../../services/api/role.api.service';
import {RolesSelector} from '../../../../redux/reducers/roles.reducer';
import {IRole, IRolePermissions} from '../../../../../../../common/interfaces/role';
import {DuplicateTrackingService} from '../../../../services/duplicate-tracking-service';


@Component({
  selector: 'app-roles-right-administration',
  templateUrl: './roles-right-administration.component.html',
  styleUrls: ['./roles-right-administration.component.scss'],
})
export class RolesRightAdministrationComponent extends AdministrationPageComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected renderer: Renderer2,
    protected redux: NgRedux<IAppState>,
    protected controller: RolesController,
    protected apiService: RoleApiService,
    protected toastrService: ToastrService,
    protected router: Router,
    private route: ActivatedRoute,
    protected modalService: ModalService,
    protected duplicateTrackingService: DuplicateTrackingService,
  ) {
    super();
  }

  public static ROUTE = 'roles-and-rights-administration';
  public fixedPermissions = false;
  protected onCloseRoute = 'roles-and-rights';
  protected validators = [];
  public isViewActive = false;
  private warningMessage = 'Die Berechtigung "Ansehen" ist für die Berechtigung(en) Anlegen, Bearbeiten, Löschen erforderlich.';
  private errorMessage = 'Die Rollenbezeichnung ist schon vergeben! Bitte wählen Sie eine alternative Rollenbezeichnung.';

  public model = {
    name: '',
    description: '',
    permissions: {
      bookings: {
        add: false,
        edit: false,
        view: false,
        remove: false,
      },
      playlist: {
        view: false,
        edit: false,
      },
      preview: {
        view: false,
      },
      locations: {
        add: false,
        edit: false,
        view: false,
        remove: false,
      },
      medias: {
        add: false,
        edit: false,
        view: false,
        remove: false,
      },
      remote: {
        view: false,
      },
    } as IRolePermissions,
  };

  protected onConflict() {
    this.toastrService.error('', this.errorMessage);
  }

  protected getParamsSub() {
    return this.route.params
      .pipe(flatMap(({id}) => {
        this.id = id;
        this.fixedPermissions = id === '1';
        return this.redux.select(RolesSelector.getById(id));
      }))
      .subscribe((role?: IRole) => {

        if (!role) {
          return;
        }

        this.model.name = role.name;
        this.model.description = role.description;
        this.model.permissions = role.permissions;
      });
  }

  protected isModelValid(): boolean {
    return !!this.model.name;
  }

  valueChange(permissions) {
    super.onFormChange();
    if (permissions.add || permissions.edit || permissions.remove ) {
      setTimeout( () => {
        permissions.view = true;
      }, 0);
    }

    if (!permissions.view && permissions.add || !permissions.view && permissions.edit || !permissions.view && permissions.remove ) {
      this.toastrService.warning('', this.warningMessage);
    }
  }
}
