import {Component, Input} from '@angular/core';
import {AbstractValueAccessor, MakeProvider} from '../abstract-value-accessor/abstract-value-accessor';

@Component({
  selector: 'ba-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [MakeProvider(BaTextInputComponent)],
})
export class BaTextInputComponent extends AbstractValueAccessor<any> {

  @Input() id: string | number | number[];
  @Input() class: string;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() title: string;
  @Input() name: string;
  @Input() formControlName: string;
  @Input() isDisabled: boolean;
  @Input() isReadonly: boolean;
  @Input() isRequired: string;
  @Input() label: string;
  @Input() description: string;

  constructor() {
    super();
    this.value = undefined;
  }

}
