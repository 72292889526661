import {IRuntime} from '../../../../../common/interfaces/location';
import {fromDateToHoursMinutes, timeStringToDate} from '../../../../../common/moment-util';
import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {IStateLocation} from '../../redux/reducers/locations.reducer';
import defaultRunTimes from '../../../../../common/runtimes';

@Injectable()
export class Runtimes {

  private locationModel: IStateLocation | any = {
    active: true,
    name: null,
    width: null,
    height: null,
    city: null,
    country: '',
    timezone: '',
    zip: null,
    street_and_number: null,
    runtimes: this.fixRunTimes(defaultRunTimes),
  };

  public createModel(currentLocation, currentModel, currentRuntimes) {

    if (!currentModel) {
      currentModel = this.locationModel;
    }

    currentModel.active = currentLocation.active;
    currentModel.name = currentLocation.name;
    currentModel.width = currentLocation.width;
    currentModel.height = currentLocation.height;
    currentModel.city = currentLocation.city;
    currentModel.country = currentLocation.country;
    currentModel.timezone = currentLocation.timezone;
    currentModel.zip = currentLocation.zip || null;
    currentModel.street_and_number = currentLocation.street_and_number || null;
    currentModel.runtimes = !currentRuntimes ? this.fixRunTimes(currentLocation.runtimes) : this.convertRunTimes(currentRuntimes) as IRuntime[];
    currentModel.attributes = currentLocation.attributes || [{ key: '', value: '' }];

    return currentModel;
  }

  public fixRunTime(runTime: IRuntime) {
    const startTime = timeStringToDate(runTime.startTime) as any;
    const endTime = timeStringToDate(runTime.endTime) as any;
    return Object.assign({}, runTime, {startTime, endTime});
  }

  public fixRunTimes(runTimes: IRuntime[]) {
    return _.map(runTimes, this.fixRunTime);
  }

  public convertRuntime(runTime: IRuntime) {
    const startTime = typeof runTime.startTime === 'string' ? runTime.startTime : fromDateToHoursMinutes(runTime.startTime);
    const endTime = typeof runTime.endTime === 'string' ? runTime.endTime : fromDateToHoursMinutes(runTime.endTime);
    return Object.assign({}, runTime, {startTime, endTime});
  }

  public convertRunTimes(runTimes) {
    return _.map(runTimes, this.convertRuntime);
  }

}
