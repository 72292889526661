import * as moment from 'moment';

const EU_FORMAT = 'DD.MM.YYYY';
const TIME_FORMAT = 'HH:mm:ss';

const DAY = 'day';
const ZERO_DATE = {year: 1970, month: 1, date: 1};

type Inclusivity = '()' | '[)' | '(]' | '[]';

export function isNowInclusiveBetweenMinutes(
  startTime: string | null,
  endTime: string | null,
  format = TIME_FORMAT,
  date = moment(),
  inclusivity: Inclusivity = '[)',
) {
  if (!startTime || !endTime) {
    return true;
  }

  const now = date.set(ZERO_DATE);

  const startTimeDate = moment(startTime, format).set(ZERO_DATE);
  const endTimeDate = moment(endTime, format).set(ZERO_DATE);

  return now.isBetween(startTimeDate, endTimeDate, 'minute', inclusivity);
}

export function isTodayInclusiveBetweenDates(startDate: string, endDate: string | null, format = EU_FORMAT, date = moment()) {
  const startMomentDate = moment(startDate, format);

  if (!endDate) {
    return date.isAfter(startMomentDate, DAY) || date.isSame(startMomentDate, DAY);
  }

  const endMomentDate = moment(endDate, format);
  return date.isBetween(startMomentDate, endMomentDate, DAY, '[]');
}

export function isVisibleForDateAndTime(startDate: string, endDate?: string | null, startTime?: string | null, endTime?: string | null) {
  return isTodayInclusiveBetweenDates(startDate, endDate) && isNowInclusiveBetweenMinutes(startTime, endTime);
}
