import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OwlDateTimeIntl } from 'ng-pick-datetime';
import { AbstractValueAccessor, MakeProvider } from '../abstract-value-accessor/abstract-value-accessor';

// @ts-ignore
import * as nanoid from 'nanoid';

export const MY_MOMENT_FORMATS = {
  parseInput: 'DD.MM.YYYY HH:MM',
  fullPickerInput: 'DD.MM.YYYY HH:MM',
  datePickerInput: 'DD.MM.YYYY',
  timePickerInput: 'HH:MM',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

export class DefaultIntl extends OwlDateTimeIntl {
  cancelBtnLabel = 'Abbrechen';
  setBtnLabel = 'Okay';
  rangeFromLabel = 'von';
  rangeToLabel = 'bis';
  hour12AMLabel = 'AM';
  hour12PMLabel = 'PM';
}

@Component({
  selector: 'ba-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    MakeProvider(BaDateTimePickerComponent),
    // {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
  ],
})
export class BaDateTimePickerComponent extends AbstractValueAccessor<Date> implements OnInit {
  @Input() isRequired = true;
  @Input() isDisabled = false;
  @Input() isDark = false;
  @Input() placeholder: string;
  @Input() label: string;

  @Input() type: string;

  @Output() dateTime: EventEmitter<Date> = new EventEmitter<Date>();

  public forString: string = nanoid();
  public pickerType = 'both';
  public selectMode = 'single';
  public icon: string;

  constructor(dateTimeAdapter: DateTimeAdapter<any>) {
    super();
    dateTimeAdapter.setLocale('de-DE');
  }

  afterPickerClosed() {
    this.dateTime.emit(this.value);
  }

  ngOnInit() {
    if (this.type === 'range') {
      this.pickerType = 'calendar';
      this.selectMode = 'range';
      this.icon = 'date-icon.svg';
    } else if (this.type === 'timer') {
      this.icon = 'clock-icon.svg';
      this.pickerType = 'timer';
    } else if (this.type === 'date-time') {
      this.icon = 'date-time-icon.svg';
    } else {
      this.pickerType = 'calendar';
      this.icon = 'date-icon.svg';
    }
  }
}
