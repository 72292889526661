import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  Injectable,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import * as _ from 'lodash';
import {
  Observable,
  Subscription,
  combineLatest,
} from 'rxjs';
import {
  mergeMap,
  take,
  map,
  filter,
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

import { IAppState } from '../../../../redux/store';
import { UsersSelector } from '../../../../redux/reducers/users.reducer';
import { ModalService } from '../../../../services/modal.service';
import { BookingTemplatesSelector } from '../../../../redux/reducers/booking-templates.reducer';
import { DisplayMode, IApiGetBooking, IApiGetBookingTemplate } from '../../../../../../../common/interfaces/booking';
import { BookingApiService } from '../../../../services/api/booking.api.service';
import { BookingsController } from '../../../../redux/actions/bookings/bookings.controller';
import { BookingsSelector } from '../../../../redux/reducers/bookings.reducer';
import { LocationsActionsCreator } from '../../../../redux/actions/locations/locations.action';
import { LocationsController } from '../../../../redux/actions/locations/locations.controller';
import { BookingTemplatesController } from '../../../../redux/actions/booking-templates/booking-templates.controller';
import * as util from '../../../../../../../common/moment-util';
import { fromDateToEuFormat, guessTimezone, isAfterToday } from '../../../../../../../common/moment-util';
import { LocationsSelector } from '../../../../redux/reducers/locations.reducer';
import { EQ, FILE_FIELDNAME_PREFIX, FILE_MEDIA_FIELDNAME_PREFIX, VIDEO } from '../../../../../../../common/constants';
import { bookingTemplateTypes } from './booking-template-types';
import { AuthApiService } from '../../../../services/api/auth.api.service';
import { AdministrationPageComponent } from '../../administration-page/administration-page.component';
import { FileApiService } from '../../../../services/api/file.api.service';
import BookingRenderer from '../../../../renderer/booking-renderer';
import { LocationGroupsSelector } from '../../../../redux/reducers/location-groups.reducer';
import { CategoriesSelector } from '../../../../redux/reducers/categories.reducer';
import { IApiGetCategories } from '../../../../../../../common/interfaces/category';
import { CategoriesController } from '../../../../redux/actions/categories/categories.controller';
import { IBooking, IInput } from '../../../../../../projects/shared/@types/projects';
import { MediasController } from '../../../../redux/actions/medias/medias.controller';
import { MediasSelector } from '../../../../redux/reducers/medias.reducer';
import { IApiGetMedias } from '../../../../../../../common/interfaces/medias';
import { MediasActionsCreator } from '../../../../redux/actions/medias/medias.action';
import { BookingsProcessingActionsCreator } from '../../../../redux/actions/bookings/bookings-processing.action';
import { QueueApiService } from '../../../../services/api/queue.api.service';
import { BookingsActionsCreator } from '../../../../redux/actions/bookings/bookings.action';
import { IApiGetLocationGroup } from '../../../../../../../common/interfaces/locationGroup';
import { LocationGroupsService } from '../../../../services/location-groups.service';
import { DuplicateTrackingService } from '../../../../services/duplicate-tracking-service';
import { IApiGetLocation } from '../../../../../../../common/interfaces/location';

const DEFAULT_DURATION = 10;

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-administration.component.html',
  styleUrls: ['./booking-administration.component.scss'],
})
export class BookingAdministrationComponent extends AdministrationPageComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  public static ROUTE = 'booking-administration';

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected renderer: Renderer2,
    protected redux: NgRedux<IAppState>,
    private authApiService: AuthApiService,
    protected controller: BookingsController,
    private locationsActionCreator: LocationsActionsCreator,
    private bookingsProcessingActionCreator: BookingsProcessingActionsCreator,
    private bookingsActionCreator: BookingsActionsCreator,
    protected apiService: BookingApiService,
    protected toastrService: ToastrService,
    protected router: Router,
    private route: ActivatedRoute,
    protected modalService: ModalService,
    protected duplicateTrackingService: DuplicateTrackingService,
    private fileApiService: FileApiService,
    private bookingRenderer: BookingRenderer,
    public mediasController: MediasController,
    private mediasActionCreator: MediasActionsCreator,
    private queueApiService: QueueApiService,
    private locationGroupsService: LocationGroupsService,
    private locationsController: LocationsController,
  ) {
    super();

    const debounceTime = 1000;
    this.debouncedRefreshPreview = _.debounce(this.refreshPreview.bind(this), debounceTime);
    this.onMediaScroll = _.debounce(this.onMediaScroll, 150).bind(this);
  }

  @select(UsersSelector.getMe) user$: Observable<any>;
  @select(MediasSelector.getAll) mediasList$: Observable<IApiGetMedias[]>;
  @select(MediasSelector.getPage) activePage$: Observable<number>;
  @select(LocationGroupsSelector.getAll) locationGroupList$: Observable<IApiGetLocationGroup[]>;
  @select(CategoriesSelector.getOptions) categories$: Observable<IApiGetCategories[]>;
  @select(LocationsSelector.getChecked) checkedLocations$: Observable<IApiGetLocation[]>;

  public categoriesOptions$ = this.categories$.pipe(
    map(categories => ([
      {code: null, name: categories.length ? 'Bitte wählen' : 'Keine Kategorien verfügbar'},
      ...categories,
    ])),
  );

  private mediasActivePageSubscription: Subscription;
  private mediasListSubscription: Subscription;

  @ViewChild('contentContainer', { read: ViewContainerRef }) contentContainer: ViewContainerRef;
  @ViewChild('scaleableWrapper') scaleableWrapper: any;
  @ViewChild('scaleableContent') scaleableContent: any;

  public DisplayMode: typeof DisplayMode = DisplayMode;
  public bookingTemplate: Partial<IApiGetBookingTemplate> = {
    name: '',
    inputs: [],
    template: {} as any,
  };
  public previewResolution$ = this.checkedLocations$.pipe(
    filter(locations => Boolean(locations && locations.length)),
    map(([ first ]) => first),
    map(({ width, height }) => ({ width, height })),
  );

  public isLocationsHidden = false;
  public isMimeTypeAllowed: boolean;

  public showMedias = false;

  public isNoEnd = false;
  public isDaily = false;
  public files = {};
  public data = {};
  public mediaFile: any = {};
  private mediaFieldName = '';
  public bookingData: any = {};
  private isDuplicate: boolean;

  protected useFormData = true;
  protected onCloseRoute = 'booking-overview';

  public model = {
    thumbnail_screenshot: null,
    duration: 0,
    display_mode: null,
    locations_group_name: null,
    name: null,
    start_date: null,
    end_date: null,
    start_time: null,
    end_time: null,
    booking_template: null,
    timezoneId: null,
    data: '',
    locations: [],
    category_id: null,
  };

  public mediasModel = {
    order: '-id',
    type: {
      image: true,
      video: true,
      pdf: true,
    },
    orientation: {
      landscape: true,
      portrait: true,
      square: true,
    },
  };

  public orderItems = [{
    code: '-id',
    name: 'Neuste zuerst',
  }, {
    code: 'name',
    name: 'Alphabetisch',
  }];

  protected validators = [
    {
      validate: () => LocationsSelector.getCheckedIds(this.redux.getState()).length > 0,
      errorMessage: 'Bitte wählen Sie mindestens 1 Standort aus.',
    },
    {
      validate: () => {
        for (const input of this.bookingTemplate.inputs) {
          const isRequired = input.isRequired === undefined ? true : !!input.isRequired;

          if (_.includes(input.type, 'file') && !this.files[input.name] && isRequired) {
            return false;
          }
        }
        return true;
      },
      errorMessage: 'Bitte laden Sie die notwendigen Dateien hoch.',
    },
  ];

  private readonly debouncedRefreshPreview: Function = _.noop;

  private previewBooking = {
    id: 0,
    display_mode: DisplayMode.Standard,
    start_date: '01.01.2019',
    end_date: null,
    start_time: null,
    end_time: null,
  } as IBooking;
  private mediasItemsWrapper: HTMLElement;
  private mediasList: HTMLElement;
  private canFetchMoreMedias = true;
  private mediasActivePage: number;
  private mediasListLength = 0;

  @HostListener('window:resize') onResize() {
    this.setPreviewHeight();
  }

  public radioChange(selectedMode: DisplayMode) {
    this.model.display_mode = selectedMode;
    this.model.category_id = null;
    super.onFormChange();
  }

  private durationCalculator: (data: any) => any = (data: any) => null;

  public toggleHideLocations() {
    this.isLocationsHidden = !this.isLocationsHidden;
  }

  private setPreviewHeight() {
    // Calculate height of preview wrapper
    const previewWrapper: HTMLElement = this.document.querySelector('.preview-wrapper') as HTMLElement;
    if (!previewWrapper) {
      return;
    }

    const height = previewWrapper.clientWidth / 1.776;
    previewWrapper.style.setProperty('height', height + 'px');
  }

  public isFileVideo(fieldName: string) {
    const mimetype = _.get(this.files[fieldName], 'mimetype', '');
    return _.includes(mimetype, VIDEO);
  }

  public upperFirst(value: string) {
    return _.upperFirst(value);
  }

  public toListItems(list: string[]) {
    return _.map(list, (item: string) => ({ code: item, name: item }));
  }

  public getDropNames(name: string) {
    return _.get(this.files[name], 'name', '');
  }

  public allowBookingMode(bookingMode: string) {
    if (this.bookingTemplate.display_modes) {
      return this.bookingTemplate.display_modes.indexOf(bookingMode) >= 0;
    }

    return true;
  }

  protected isModelValid() {
    const bookingDatesValid = this.isNoEnd ? !!this.model.start_date : this.model.start_date && this.model.end_date;
    const timesValid = this.isDaily ? this.model.start_time && this.model.end_time : true;

    const isDatasValid = _.reduce(this.bookingTemplate.inputs, (acc, { type, name, validate, isRequired = true }) => {
      if (!acc) {
        return acc;
      }

      if (!isRequired) {
        return true;
      }

      const validator = validate || _.get(bookingTemplateTypes[type], 'validate', () => true);
      return validator(this.data[name] ? this.data[name].value : null);
    },
      true,
    );

    return bookingDatesValid &&
      timesValid &&
      isDatasValid &&
      this.model.name &&
      (
        (
          this.model.display_mode === DisplayMode.Category &&
          this.model.category_id
        ) ||
        this.model.display_mode !== DisplayMode.Category
      );
  }

  private stringifyDate(date: any) {
    return _.replace(JSON.stringify(date), /"/g, '');
  }

  protected async preSaveHook() {
    const id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

    const startDate = this.stringifyDate(this.model.start_date);
    const endDate = this.isNoEnd ? null : this.stringifyDate(this.model.end_date);

    this.redux.dispatch(this.bookingsProcessingActionCreator.add([{
      id,
      isAsync: true,
      isUploading: true,
      name: this.model.name,
      locations: LocationsSelector.getChecked(this.redux.getState()),
      start_date: startDate && fromDateToEuFormat(startDate),
      end_date: endDate && fromDateToEuFormat(endDate),
    }]));

    if (this.id) {
      this.redux.dispatch(this.bookingsActionCreator.deleteData([_.toNumber(this.id)], true));
    }

    this.toastrService.info('', 'Deine Buchung wird gespeichert. Bitte nicht den Browser schließen solange die Buchung hochgeladen wird');
    await this.router.navigate(['booking-overview']);

    return id;
  }

  private startJobCheckInterval(jobId: string, elementId: number) {
    const intervalId = setInterval(async () => {
      const job = await this.queueApiService.getBookingJob(jobId);
      if (!job.finishedOn) {
        return;
      }

      clearInterval(intervalId);
      await this.redux.dispatch(this.controller.updateCurrent());
      this.redux.dispatch(this.bookingsProcessingActionCreator.deleteData([elementId], true));
    }, 1000);
  }

  protected async onSuccess(response: Response, preSaveHookResult: any) {
    const jobId = await response.json();
    const id: number = preSaveHookResult;
    this.redux.dispatch(this.bookingsProcessingActionCreator.update({ id, isUploading: false }));

    this.startJobCheckInterval(jobId, id);
  }

  protected onError(preSaveHookResult: any) {
    const id: number = preSaveHookResult;
    this.redux.dispatch(this.bookingsProcessingActionCreator.deleteData([id], true));
  }

  public async initCancelModal() {
    await this.redux.dispatch(this.locationsController.updateActive());
    this.locationGroupsService.setLocationGroupForBookings(null);
    this.duplicateTrackingService.setDuplicate(false);
    super.initCancelModal();
  }

  protected async prepareModel() {
    this.model.locations = LocationsSelector.getCheckedIds(this.redux.getState());
    this.model.timezoneId = guessTimezone();
    this.model.start_date = this.stringifyDate(this.model.start_date);

    this.model.end_date = this.isNoEnd ? null : this.stringifyDate(this.model.end_date);
    this.model.start_time = this.isDaily ? this.stringifyDate(this.model.start_time) : null;
    this.model.end_time = this.isDaily ? this.stringifyDate(this.model.end_time) : null;

    this.model.locations_group_name = this.locationGroupsService.getLocationGroupForBookings();

    this.model.booking_template = this.bookingTemplate.id;

    this.model.duration = this.durationCalculator(this.data);
    this.model.data = JSON.stringify(this.data);

    this.isDuplicate = this.duplicateTrackingService.getDuplicate();

    for (const input of this.bookingTemplate.inputs) {
      const { name, type } = input;

      if (this.isDuplicate && _.includes(type, 'file')) {
        const fileKey = _.get(input, 'fileKey');
        const newFileBlob = await this.fileApiService.getFile(fileKey);
        this.files[name] = newFileBlob;
      }

      if (!_.includes(type, 'file') || !this.files[name] || this.files[name].ignore) {
        continue;
      }

      if (!this.files[name].id) {
        this.model[`${FILE_FIELDNAME_PREFIX}${name}`] = this.files[name];
      } else {
        this.model[FILE_MEDIA_FIELDNAME_PREFIX] = {
          ...this.model[FILE_MEDIA_FIELDNAME_PREFIX],
          [name]: this.files[name].id,
        };
      }
    }

    this.model[FILE_MEDIA_FIELDNAME_PREFIX] = JSON.stringify(this.model[FILE_MEDIA_FIELDNAME_PREFIX]);

    this.locationGroupsService.setLocationGroupForBookings(null);
    this.duplicateTrackingService.setDuplicate(false);
  }

  private getBookingTemplateSource(moduleName: string) {
    return this.fileApiService.getFile(moduleName, 'text');
  }

  private resizeContent() {
    if (!this.scaleableWrapper || !this.scaleableContent) {
      return;
    }

    const { clientHeight, clientWidth } = this.scaleableWrapper.nativeElement;
    const { offsetHeight, offsetWidth } = this.scaleableContent.nativeElement;
    this.scaleableContent.nativeElement.style.transform = `scale(${Math.min(clientHeight / offsetHeight, clientWidth / offsetWidth)})`;
  }

  public async ngAfterViewInit() {
    const getAuthTokenCallback = this.authApiService.getAuthToken.bind(this.authApiService);
    this.bookingRenderer.setGetAuthTokenCallback(getAuthTokenCallback);
    this.bookingRenderer.setBookingsAlwaysVisible(true);

    const getFileCallback = async (key: string) => {
      const fileBlob = this.files[key] ? this.files[key] : await this.fileApiService.getFile(key);
      return URL.createObjectURL(fileBlob);
    };

    const getTemplateSourceCallback = this.getBookingTemplateSource.bind(this);

    _.set(this.previewBooking, 'booking_template.template.key', this.bookingTemplate.template.key);

    this.updatePreviewBookingWithData();
    this.updatePreviewBookingWithFiles();

    await this.bookingRenderer.init(this.contentContainer, [this.previewBooking], {} as any, getTemplateSourceCallback, getFileCallback);

    this.setPreviewHeight();
  }

  public ngAfterViewChecked() {
    if (this.contentContainer) {
      this.resizeContent();
    }
  }

  public onFileChange(newFile: File | any, fieldname: string) {
    this.duplicateTrackingService.setDuplicate(false);
    if (newFile) {
      newFile.mimetype = newFile.type;
      newFile.fieldname = fieldname;
      newFile.key = fieldname;

      _.set(this.files, fieldname, newFile);
    } else {
      _.unset(this.files, fieldname);
    }

    return this.debouncedRefreshPreview();
  }

  private updatePreviewBookingWithFiles() {
    _.set(this.previewBooking, 'files', _.values(_.cloneDeep(this.files)));
  }

  public colorPickerChanged(data: any, inputName: string) {
    const valuePath = `${inputName}.value`;
    if (_.isEqual(_.get(this.data, valuePath), data)) {
      return;
    }
    super.onFormChange();
    _.set(this.data, valuePath, data);
    return this.debouncedRefreshPreview();
  }

  public onDataChange(data: any, inputName: string) {
    const valuePath = `${inputName}.value`;
    if (_.isEqual(_.get(this.data, valuePath), data)) {
      return;
    }
    super.onFormChange();
    _.set(this.data, valuePath, data);
    return this.debouncedRefreshPreview();
  }

  private async refreshPreview() {
    this.updatePreviewBookingWithFiles();
    this.updatePreviewBookingWithData();

    this.bookingRenderer.setBookingsAlwaysVisible(true);
    this.bookingRenderer.setContentContainer(this.contentContainer);

    await this.bookingRenderer.update([_.cloneDeep(this.previewBooking)], {} as any);
    this.bookingRenderer.stop();
    this.bookingRenderer.pauseOrPlay();
  }

  private updatePreviewBookingWithData() {
    const newInputs = _.map(this.bookingTemplate.inputs, (input: IInput) => {

      let value = _.get(this.data, `${input.name}.value`);

      if (typeof value === 'undefined') {
        // TODO add possibility to show fallback text defined in template meta if exist else show "BEISPIELTEXT"
        // input.default ? value = input.default : value = _.get(bookingTemplateTypes, `[${input.type}].defaultValue`);
        value = _.get(bookingTemplateTypes, `[${input.type}].defaultValue`);
      }

      const fileKey = _.get(this.data, `${input.name}.fileKey`);
      return Object.assign(input, typeof value === 'undefined' ? {} : { value }, fileKey ? { fileKey } : {});
    });

    _.set(this.previewBooking, 'inputs', newInputs);
    _.set(this.previewBooking, 'duration', this.durationCalculator(this.data) || DEFAULT_DURATION);
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.renderer.removeClass(this.document.body, 'overlay');

    this.bookingRenderer.destroy();
  }

  public onInitBooking(booking?: IApiGetBooking) {
    if (!booking) {
      return;
    }

    this.bookingData = booking;

    this.isMimeTypeAllowed = true;

    this.id = booking.id;
    this.model.name = booking.name;
    this.model.display_mode = booking.display_mode;
    this.model.category_id = booking.category_id;

    this.isNoEnd = !booking.end_date;
    this.isDaily = (!!booking.start_time && !!booking.end_time);

    this.model.start_date = util.dateStringToDate(booking.start_date);
    if (booking.end_date) {
      this.model.end_date = util.dateStringToDate(booking.end_date);
    }

    if (booking.start_time && booking.end_time) {
      this.model.start_time = util.timeStringToDate(booking.start_time);
      this.model.end_time = util.timeStringToDate(booking.end_time);
    }

    if (!booking.locations_group_name) {
      _.each(booking.locations, (location) => {
        this.redux.dispatch(this.locationsActionCreator.setChecked(location.id, true));
      });
    }

    this.data = _.reduce(booking.inputs, (acc, input) => {
      const inputObject = {
        type: input.type,
        value: input.value,
      };
      if (input.fileKey) {
        // @ts-ignore
        inputObject.fileKey = input.fileKey;
      }
      acc[input.name] = inputObject;

      return acc;
    }, {});

    const fileInputs = booking.inputs.filter(input => input.type === 'file' || input.type === 'fileWithDuration');
    fileInputs.forEach((fileInput) => {
      const fieldName = fileInput.name;
      const file = booking.files.find(bookingFile => bookingFile.key === fileInput.fileKey);
      if (file) {
        this.files[fieldName] = file;
        this.files[fieldName].ignore = true;
      }
    });
  }

  protected getParamsSub() {
    return this.route.params
      .pipe(
        mergeMap(({ bookingTemplateId, bookingId }) => combineLatest([
          this.redux.select(BookingTemplatesSelector.getById(bookingTemplateId)),
          this.redux.select(BookingsSelector.getById(bookingId)),
        ])),
        take(1),
      )
      .subscribe(([bookingTemplate, booking]) => {
        this.bookingTemplate = bookingTemplate || this.bookingTemplate;
        if (this.bookingTemplate.display_modes) {
          if (this.allowBookingMode('STANDARD')) {
            this.model.display_mode = DisplayMode.Standard;
          } else if (this.allowBookingMode('PRIORITY')) {
            this.model.display_mode = DisplayMode.Priority;
          } else if (this.allowBookingMode('FALLBACK')) {
            this.model.display_mode = DisplayMode.Fallback;
          } else if (this.allowBookingMode('CATEGORY')) {
            this.model.display_mode = DisplayMode.Category;
          }
        } else {
          this.model.display_mode = DisplayMode.Standard;
        }

        // tslint:disable
        this.durationCalculator = eval(this.bookingTemplate.duration_calculator);

        _.each(this.bookingTemplate.inputs, (input) => {
          this.data[input.name] = Object.assign(
            this.data[input.name] || {},
            { type: input.type },
            // TODO Add possibility to define default input text defined in template meta
            // typeof input.defaultInput === 'undefined' ? {} : { value: input.defaultInput }
            typeof input.default === 'undefined' ? {} : { value: input.default }
          );
        });

        this.onInitBooking(booking);
      });
  }


  private onMediasModelChange() {
    this.canFetchMoreMedias = true;
    this.redux.dispatch(this.mediasActionCreator.changePage(1));
    this.redux.dispatch(this.mediasController.updateCurrent());
  }

  public onMediasOrderChange(orderObj) {
    this.mediasModel = {
      ...this.mediasModel,
      ...orderObj,
    };

    this.redux.dispatch(this.mediasActionCreator.toggleSort(this.mediasModel.order));

    this.onMediasModelChange();
  }

  public onMediasFilterChange({ model, changedKey, changedValue }) {
    this.mediasModel = model;
    this.redux.dispatch(this.mediasActionCreator.setFilter(changedValue, changedKey));

    this.onMediasModelChange();
  }

  public displayMediasLibrary(isOpen, fieldName = null) {
    if (isOpen) {
      this.redux.dispatch(this.mediasController.updateActive());
    }
    this.showMedias = isOpen;
    this.mediaFieldName = fieldName;
  }

  onMediasSidebarInit(isOpen) {
    if (isOpen) {
      this.mediasItemsWrapper = document.querySelector('.medias-sidebar .list-items');
      this.mediasList = document.querySelector('.medias-sidebar .list');

      this.mediasList.addEventListener('scroll', this.onMediaScroll);

      this.mediasActivePageSubscription = this.activePage$.subscribe((activePage: any) => {
        this.mediasActivePage = activePage;
      });
      this.mediasListSubscription = this.mediasList$.subscribe((mediasList: IApiGetMedias[]) => {
        if (mediasList.length !== this.mediasListLength) {
          this.canFetchMoreMedias = true;
        }
        this.mediasListLength = mediasList.length;
      });
    } else {
      this.mediasList.removeEventListener('scroll', this.onMediaScroll);
      this.mediasActivePageSubscription.unsubscribe();
      this.mediasListSubscription.unsubscribe();
    }
  }

  onMediaScroll() {
    const wrapperDimensions = this.mediasItemsWrapper.getBoundingClientRect();
    const wrapperBottom = wrapperDimensions.bottom;
    const browserHeight = window.innerHeight;

    if (wrapperBottom <= browserHeight && this.canFetchMoreMedias) {
      this.canFetchMoreMedias = false;
      this.redux.dispatch(this.mediasActionCreator.changePage(this.mediasActivePage + 1));
      this.redux.dispatch(this.mediasController.getMore());
    }
  }

  private getPickedMedia(mediaId) {
    this.redux.select(MediasSelector.getById(mediaId))
      .subscribe(media => {
        this.mediaFile = media;
      });
  }

  public onMediaPick(mediaId) {
    this.getPickedMedia(mediaId);
    this.files = {
      ...this.files,
      [this.mediaFieldName]: this.mediaFile.file,
    };
    _.set(this.data, `${this.mediaFieldName}.fileKey`, this.mediaFile.file.key);

    this.showMedias = false;
    this.debouncedRefreshPreview();
  }
}

@Injectable()
export class BookingFormDataResolver implements Resolve<any> {
  constructor(
    private redux: NgRedux<IAppState>,
    private locationsController: LocationsController,
    private bookingApiService: BookingApiService,
    private bookingsController: BookingsController,
    private bookingTemplatesController: BookingTemplatesController,
    private categoriesController: CategoriesController,
  ) {
  }

  async resolve(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const { bookingId } = routeSnapshot.params;

    await Promise.all([
      this.redux.dispatch(this.locationsController.updateActive()),
      this.redux.dispatch(this.bookingTemplatesController.updateActive()),
      this.redux.dispatch(this.categoriesController.updateActive()),
    ]);

    if (!bookingId || await new Promise((resolve) => this.redux.select(BookingsSelector.getById(bookingId)).subscribe(resolve as any))) {
      return;
    }

    const bookings: IApiGetBooking[] = await this.bookingApiService.getByFiltersAndSortings([{ column: 'id', operator: EQ, value: bookingId }]);
    if (bookings.length === 0) {
      return;
    }
    const booking = bookings[0];

    await this.redux.dispatch(isAfterToday(booking.end_date) ? this.bookingsController.updateActive() : this.bookingsController.updateInActive());
  }
}
