import * as _ from 'lodash';
import {AbstractReducer, IMetaState, IStateDataType} from './abstract.reducer';
import {IStringMap} from '../../../../../common/interfaces/util';
import {ILocationsAction, LocationsActionsCreator} from '../actions/locations/locations.action';
import {IApiGetLocation} from '../../../../../common/interfaces/location';
import {LOCATIONS} from '../../../../../common/constants';

export type ILocationsState = IMetaState<IStateLocation>;

export interface IStateLocation extends IApiGetLocation, IStateDataType {
  selected: boolean;
}

export interface IAndLocationFilters extends IStringMap {
  id: string;
  name: string;
  city: string;
}

export interface ILocationFilters extends IAndLocationFilters {
  resolution: string;
}

export class LocationsReducer extends AbstractReducer<IStateLocation, ILocationsAction> {
  private constructor() {
    super(LOCATIONS, LocationsActionsCreator.getInstance());
  }

  private static instance: LocationsReducer;

  public DEFAULT_STATE: IMetaState<IStateLocation> = _.assign({}, AbstractReducer.DEFAULT_STATE, {
    filters: {id: '', name: '', resolution: '', city: ''} as ILocationFilters,
    sortings: {id: 'ASC'},
  });

  public static getInstance() {
    if (!LocationsReducer.instance) {
      LocationsReducer.instance = new LocationsReducer();
    }

    return LocationsReducer.instance;
  }
}

export const LocationsSelector = LocationsReducer.getInstance().getSelectors();
