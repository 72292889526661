import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {full} from '../../../../../../../../common/routes';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../../../../../redux/store';
import {IDeleteTexts, ModalService} from '../../../../../services/modal.service';
import {AbstractCrudController} from '../../../../../redux/actions/abstractCrud.controller';
import {AbstractActionsCreator} from '../../../../../redux/actions/abstractActionsCreator';
import {IColumn} from '../table.component';
import {WarningModalBoxComponent} from '../../../../ui-elements/modal-box/warning-modal-box/warning-modal-box.component';
import {Router} from '@angular/router';
import {AuthApiService} from '../../../../../services/api/auth.api.service';
import {UsersSelector} from '../../../../../redux/reducers/users.reducer';
import {Observable} from 'rxjs';
import * as _ from 'lodash';
import {DuplicateTrackingService} from '../../../../../services/duplicate-tracking-service';

@Component({
  selector: 'app-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
})
export class RowComponent<dataType> implements OnInit, OnDestroy {

  @select(UsersSelector.getMe) user$: Observable<any>;

  @Input() id: number;
  @Input() useEditButtons = true;
  @Input() editButtonReplaceComponent = null;
  @Input() editButtonReplaceComponentInputs = {};

  @Input() fixedWidth = false;
  @Input() isCheckable = true;
  @Input() deleteTexts: IDeleteTexts;
  @Input() data: any;
  @Input() columns: IColumn[];
  @Input() isEditable = true;
  @Input() isReplaceable = false;
  @Input() isBlue = false;
  @Input() editOrAddLinkGetter: (rowData: any) => string;
  @Input() duplicateLinkGetter: (rowData: any) => string;
  @Input() useDuplicate: boolean;
  @Input() actionFactory: AbstractActionsCreator<dataType>;
  @Input() name: string;
  @Input() crudController: AbstractCrudController;
  @Input() isToggleSwitched = false;
  @Input() rowEndInformation: (data: any) => any;
  @Input() isRowSelected: Function = _.noop;
  @Input() whenCheckboxChanged: Function = _.noop;
  @Input() downloadLink = '';

  @Output() elementReplace = new EventEmitter<any>();

  public fileApiRoute = full.files;
  public isListVisible: boolean;
  public toggledColumnName: string;

  @ViewChild('editButtonReplaceComponentContainer', {read: ViewContainerRef}) editButtonReplaceComponentViewContainerRef: ViewContainerRef;
  private editButtonReplaceComponentRef: ComponentRef<any>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private redux: NgRedux<IAppState>,
    private modalService: ModalService,
    private router: Router,
    public authApiService: AuthApiService,
    private duplicateTrackingService: DuplicateTrackingService,
  ) {
  }

  ngOnInit() {
    if (!this.editButtonReplaceComponent) {
      return;
    }

    // create microtask here to avoid angular error and to get the ViewContainerRef
    // see: https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4
    return Promise.resolve().then(() => {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.editButtonReplaceComponent);
      this.editButtonReplaceComponentRef = this.editButtonReplaceComponentViewContainerRef.createComponent(componentFactory);
      Object.assign(this.editButtonReplaceComponentRef.instance, this.editButtonReplaceComponentInputs, {data: this.data});
    });
  }

  ngOnDestroy(): void {
    if (this.editButtonReplaceComponentRef) {
      this.editButtonReplaceComponentRef.destroy();
    }

    if (this.editButtonReplaceComponentViewContainerRef) {
      this.editButtonReplaceComponentViewContainerRef.clear();
    }
  }

  getIconSrcForColumn(column: IColumn) {
    return column.dataGetter(this.data);
  }

  getImgSrcForColumn(column: IColumn) {
    return this.fileApiRoute + column.dataGetter(this.data) + '?' + this.authApiService.getAuthTokenQueryString();
  }

  getFinalEditOrAddLink(column: IColumn) {
    return (Array.isArray(column.dataGetter(this.data)) || this.isCheckable) ? undefined : this.getEditOrAddLink(this.data);
  }

  isArray(array: any[]) {
    return Array.isArray(array);
  }

  toggleListVisible(columnName: string, event: any) {
    event.stopPropagation();
    this.toggledColumnName = columnName;
    this.isListVisible = !this.isListVisible;
  }

  getEditOrAddLink(data: any) {
    return (this.isEditable && this.editOrAddLinkGetter) ? this.editOrAddLinkGetter(data) : undefined;
  }

  initDeleteModalCreator(id: number) {
    if (!this.useEditButtons) {
      return;
    }
    return (event: Event) => {
      event.stopPropagation();
      this.modalService.open(WarningModalBoxComponent, {
        isMobile: false,
        onSubmit: () => this.redux.dispatch(this.crudController.deleteData(id)),
        deleteTexts: this.deleteTexts,
      });
    };
  }

  initDuplicateLinkCreator(data: any) {
    if (this.isEditable && this.editOrAddLinkGetter) {
      return async (event: Event) => {
        if (data && data.display_mode) {
          this.duplicateTrackingService.setDuplicate(true);
        }
        const duplicateRowData = _.cloneDeep(data);
        delete duplicateRowData.id;
        delete duplicateRowData.index;
        delete duplicateRowData.isChecked;
        duplicateRowData.name = duplicateRowData.name + '_COPY';
        await this.redux.dispatch(this.crudController.addData([duplicateRowData]));
        await this.router.navigate([this.duplicateLinkGetter(data)]);
        await this.redux.dispatch(this.crudController.updateCurrent());
      };
    }

    return _.noop;
  }

  initEditLinkCreator(data: any) {
    if (this.isEditable && this.editOrAddLinkGetter) {
      return (event: Event) => this.router.navigate([this.editOrAddLinkGetter(data)]);
    }

    return _.noop;
  }

  selectRow() {
    if (!this.isCheckable) {
      return;
    }

    return this.redux.dispatch(this.actionFactory.toggleChecked(this.id));
  }

  onElementReplace(data) {
    this.elementReplace.emit(data);
  }
}
